import './index.css';
import { Options } from './Interfaces/options';
import templates from './Helpers/templates';
import { DEFAULT_CONFIG } from './Helpers/defaults';
import { wrap } from './Helpers/wrap';
import DomainSearch from './Components/DomainSearch';
import { defineCustomElements } from '@rocketspark/flint-ui/loader';
import '@rocketspark/flint-ui/dist/rocketspark/rocketspark.css';
import PopupContainer from './Components/PopupContainer';
import { DomainResponse } from './Helpers/Fetch';
import { Locale } from './Interfaces/locale';
import { logEvent } from './Helpers/analytics';

// noinspection JSIgnoredPromiseFromCall
defineCustomElements();

class DomainChecker {
    public static PRODUCTION_GET_IT_NOW = 'https://www.rocketspark.com/site_files/1/custom/1/packages/freeTrialRedirect.php?domainName=';
    public static DEVELOPMENT_GET_IT_NOW = 'http://blastoff.cms.localhost?signupType=domain&domainName=';
    public static PRODUCTION_FETCH = 'https://cms.rocketspark.co.nz/tools/checkDomainRegisterAvailability.php';
    public static DEVELOPMENT_FETCH = 'https://cms.cms.localhost/tools/checkDomainRegisterAvailability.php';
    private readonly element: HTMLElement;
    private readonly config: Options;
    private readonly locale: Locale;
    private readonly allowedLocales = ['NZ', 'AU', 'UK', 'US'];
    private domainSearch: DomainSearch;
    private popupContainer: PopupContainer;


    public constructor(element: HTMLElement, options: Partial<Options> = {}) {
        this.element = element;

        this.config = {
            ...DEFAULT_CONFIG,
            ...options,
        };

        const locale = this.element.dataset.locale?.toUpperCase() ?? null;
        if (locale === null || !this.allowedLocales.includes(locale)) {
            throw TypeError(
                `data-locale is a required attribute, it must a string, and it should be one of: ${this.allowedLocales.join('|')}`
            );
        }

        this.locale = locale as Locale;

        this.init();
        this.startListening();
    }

    public static setPopupStatus(popupContainer: PopupContainer, { success, isAvailable, formattedPrice, error }: Partial<DomainResponse>): void {

        if (success) {
            popupContainer.showSearchAgain();
            if (isAvailable) {
                popupContainer.setPrice(formattedPrice);
                popupContainer.setHeading('This domain is available');
            } else {
                popupContainer.setPrice('unavailable');
                popupContainer.setHeading('This domain is unavailable');
            }
        } else {
            if (error.includes('are not available for new registrations by Rocketspark')) {
                popupContainer.setHeading('This domain is unavailable');
                const message = error.replace('for new registrations by Rocketspark', 'from Rocketspark');
                popupContainer.setCaptionMessage(message);
            } else {
                popupContainer.setHeading('Please try searching again');
                popupContainer.setPrice(null);
            }
        }
    }

    private autofillSearchBar(): void {
        const domain = this.getDomainFromUrl();
        if (domain) {
            this.domainSearch.setDomain(domain);
            this.domainSearch.setLoading(true);
            this.domainSearch.submit();
            this.popupContainer.setPrice(null);
            this.popupContainer.open();
            this.popupContainer.setDomain(domain);
            this.popupContainer.setLoading(true);
        }
    }

    private getDomainFromUrl(): string | null {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('domain');
    }

    private init(): void {
        this.element.classList.add(this.config.classNames.element);
        const container = this.getTemplate('container');
        const inputContainer = this.getTemplate('inputContainer');

        this.domainSearch = new DomainSearch({
            element: this.getTemplate('domainSearch'),
            config: this.config,
            locale: this.locale
        });

        this.popupContainer = new PopupContainer({
            element: this.getTemplate('popupContainer'),
            config: this.config,
            locale: this.locale
        });

        wrap(this.domainSearch.element, inputContainer);
        wrap(inputContainer, container);
        wrap(container, this.element);

        this.autofillSearchBar();

    }

    private getTemplate(template: keyof typeof templates, ...args: any): any {
        return templates[template].call(this, this.config, ...args);
    }

    private startListening(): void {
        this.domainSearch.element.addEventListener('domainSearchStart', ({ detail }: CustomEvent) => {
            logEvent('domains_search_start', { domain: detail });
            this.popupContainer.setPrice(null);
            this.popupContainer.open();
            this.popupContainer.setDomain(detail);
            this.popupContainer.setLoading(true);
        });
        this.domainSearch.element.addEventListener('domainSearchFinish', (event: CustomEvent<DomainResponse>) => {
            DomainChecker.setPopupStatus(this.popupContainer, event.detail);
            this.popupContainer.showAndFetchDomainRows(event.detail);
            this.popupContainer.setLoading(false);
        });
    }
}

export default DomainChecker;

document.addEventListener('DOMContentLoaded', () => {
    document
        .querySelectorAll('.rs-domain-check')
        .forEach((element: HTMLElement) => {
            new DomainChecker(element);
        });
});
