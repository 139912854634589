import {ClassNames} from '../Interfaces/class-names';
import {DomainResponse, FetchDomain} from '../Helpers/Fetch';
import {wrap} from '../Helpers/wrap';
import {Locale} from '../Interfaces/locale';
import Cart from './Cart';

export default class SearchAlternativeRow {
    public readonly element: HTMLRsHeaderElement;
    private readonly classNames: ClassNames;
    private readonly header: HTMLRsHeaderElement;
    private readonly prefix: string;
    private readonly suffix: string;
    private readonly getItNow: HTMLSpanElement;
    private readonly domain: string;
    private locale: Locale;
    private cart: Cart;
    private pricing: HTMLDivElement;
    private blurb: HTMLSpanElement;
    private domainDetails: DomainResponse;

    public constructor({
                           element,
                           prefix,
                           suffix,
                           classNames,
                           locale,
                           cart,
                       }: {
        element: HTMLRsHeaderElement;
        prefix: string;
        suffix: string;
        classNames: ClassNames;
        locale: Locale;
        cart: Cart;
    }) {
        this.element = element;
        this.prefix = prefix;
        this.suffix = suffix;
        this.locale = locale;
        this.cart = cart;
        this.domain = this.prefix + this.suffix;
        this.classNames = classNames;
        this.header = this.element.querySelector(
            `.${this.classNames.searchAlternativeRowHeader}`
        );
        this.getItNow = this.element.querySelector(
            `.${this.classNames.getItNowButton}`
        );
        this.pricing = this.element.querySelector(
            `.${this.classNames.searchAlternativePricingContainer}`
        );

        this.build();
        this.fetchDetails();
    }

    private build(): void {
        this.header.innerHTML = this.buildDomain();
    }

    private buildDomain(): string {
        return `${this.prefix}<span class="${this.classNames.searchAlternativeDomainSuffix}">${this.suffix}</span>`;
    }

    private addListeners(): void {
        this.element.addEventListener('click', () => {
            if (!this.getItNow) {
                console.error('getItNow element not found');
                return;
            }

            if (this.getItNow.classList.contains(this.classNames.getItNowButtonSelected)) {
                // Revert to original state and remove domain from array
                this.getItNow.classList.remove(this.classNames.getItNowButtonSelected);
                this.getItNow.innerHTML = 'Get it now';
                // Remove domain from the shopping cart
                this.cart.removeDomain(this.domain);
            } else {
                // Change to selected state with tick icon and add domain to array
                this.getItNow.textContent = '';
                this.getItNow.classList.add(this.classNames.getItNowButtonSelected);
                this.getItNow.innerHTML = '<rs-icon name="tick" color="white"></rs-icon>';
                this.cart.addDomain(this.domain, this.domainDetails);
            }
        });
        // Check if the domain is already in the cart
        if (this.cart.isInCart(this.domain)) {
            this.getItNow.classList.add(this.classNames.getItNowButtonSelected);
            this.getItNow.innerHTML = '<rs-icon name="tick" color="white"></rs-icon>';
        }
    }

    private fetchDetails(): void {
        FetchDomain(this.domain, this.locale, 0)
            .then((domainResponse) => {
                const {isAvailable, formattedPrice, formattedYearlyPrice, success} = domainResponse;

                if (!success) {
                    this.element.remove();
                    this.domainDetails = null;
                }
                this.domainDetails = domainResponse;

                this.element.classList.remove(this.classNames.searchAlternativeRowFetching);
                this.getItNow.textContent = 'Get it now';
                if (!isAvailable) {
                    this.element.classList.add(this.classNames.searchAlternativeRowUnavailable);
                    this.getItNow.classList.add(this.classNames.getItNowButtonUnavailable);
                    this.getItNow.textContent = 'UNAVAILABLE';
                    this.getItNow.setAttribute('disabled', 'true');
                    const mainContainer = document.querySelector(`.${this.classNames.domainSearchContainer}`);
                    if (mainContainer) {
                        mainContainer.appendChild(this.element);
                    }
                    return;
                }

                if (formattedYearlyPrice && formattedYearlyPrice !== formattedPrice) {
                    this.pricing.innerHTML = `
                    <div><span class="${this.classNames.searchAlternativePricingDiscount}">${formattedYearlyPrice}</span> <span class="${this.classNames.searchAlternativePricing}">${formattedPrice}</span></div>
                `;

                    this.blurb = Object.assign(document.createElement('div'), {
                        textContent: 'For the first year',
                        className: this.classNames.searchAlternativePricingBlurb,
                    });
                } else {
                    this.pricing.innerHTML = `
                    <div class="${this.classNames.searchAlternativePricing}">${formattedPrice}</div>
                `;
                    this.blurb = Object.assign(document.createElement('div'), {
                        textContent: 'Per year',
                        className: this.classNames.searchAlternativePricingBlurb,
                    });
                }
                wrap(this.blurb, this.pricing);

                this.addListeners();
            })
            .catch(() => {
                this.element.remove();
            });
    }
}
