import { ClassNames } from '../Interfaces/class-names';
import { Options } from '../Interfaces/options';

const namespace = 'dc__';

const DEFAULT_CLASSNAMES: ClassNames = {
    element: `${namespace}checker`,
    containerOuter: `${namespace}container`,
    inputContainer: `${namespace}input-container`,
    inputContainerSearching: `${namespace}input-container--searching`,
    domainInput: `${namespace}domain-input`,
    searchButton: `${namespace}search-button`,
    searchButtonCustom: `${namespace}search-button--custom`,

    loadingBarContainer: `${namespace}loading-bar-container`,
    loadingBar: `${namespace}loading-bar`,

    popupContainer: `${namespace}popup-container`,
    domainContainer: `${namespace}domain-container`,
    popupContainerActive: `${namespace}popup-container--active`,
    popupSearchContainer: `${namespace}popup-search-container`,
    popupClose: `${namespace}popup-close`,
    popupSearchSpinner: `${namespace}popup-search-spinner`,
    searchHeading: `${namespace}search-heading`,
    searchAgain: `${namespace}search-again`,
    searchAgainActive: `${namespace}search-again--active`,
    searchResultCaption: `${namespace}search-result-caption`,
    searchResultCaptionPrice: `${namespace}search-result-caption__price`,
    searchResultCaptionActive: `${namespace}search-result-caption--active`,

    searchAlternativeContainer: `${namespace}search-alternatives`,
    searchAlternativeContainerTitle: `${namespace}search-alternatives__title`,
    searchAlternativeContainerContent: `${namespace}search-alternatives__content`,
    searchAlternativeContainerActive: `${namespace}search-alternatives--active`,
    searchAlternativeHeader: `${namespace}search-alternatives__header`,
    searchAlternativeRow: `${namespace}search-alternatives__row`,

    searchPreferredHeader: `${namespace}search-alternatives__preferred-header`,
    searchPreferredRow: `${namespace}search-preferred__row`,
    searchPreferredPill: `${namespace}search-preferred__pill`,

    getItNowButtonContainer: `${namespace}get-it-now-button-container`,
    getItNowButton: `${namespace}get-it-now-button`,
    getItNowButtonPreferred: `${namespace}get-it-now-button--preferred`,
    getItNowButtonAlternative: `${namespace}get-it-now-button--alternative`,
    getItNowButtonSelected: `${namespace}get-it-now-button--selected`,
    getItNowButtonUnavailable: `${namespace}get-it-now-button--unavailable`,
    getItNowButtonLoading: `${namespace}get-it-now-button--loading`,

    domainSearchContainer: `${namespace}search-alternatives-container`,

    cartContainer: `${namespace}cart-container`,
    cart: `${namespace}cart`,
    cartHeading: `${namespace}cart-heading`,
    cartCaption: `${namespace}cart-caption`,
    cartButton: `${namespace}cart-button`,
    cartRow: `${namespace}cart-row`,
    cartRowsContainer: `${namespace}cart-rows-container`,
    cartRowRemove: `${namespace}cart-row-remove`,
    cartRowDomainDetails: `${namespace}cart-row__domain-details`,
    cartRowDomainDetailsURL: `${namespace}cart-row__domain-details-url`,
    cartRowDomainDetailsContainer: `${namespace}cart-row__domain-details__container`,
    cartRowDomainDetailsPrice: `${namespace}cart-row__domain-details-price`,
    cartPriceSummary: `${namespace}cart-price-summary`,

    searchAlternativeRowUnavailable: `${namespace}search-alternatives__row--unavailable`,
    searchAlternativeRowFetching: `${namespace}search-alternatives__row--fetching`,
    searchAlternativeRowHeader: `${namespace}search-alternatives__row-header`,
    searchAlternativeRowHeaderContainer: `${namespace}search-alternatives__row-header-container`,
    searchAlternativeDomainSuffix: `${namespace}search-alternatives__domain-suffix`,
    searchAlternativePricingContainer: `${namespace}search-alternatives__pricing-container`,
    searchAlternativePricing: `${namespace}search-alternatives__pricing`,
    searchAlternativePricingDiscount: `${namespace}search-alternatives__pricing-discount`,
    searchAlternativePricingBlurb: `${namespace}search-alternatives__pricing-blurb`,

    checkboxContainer: `${namespace}checkbox-container`,
    checkboxLabel: `${namespace}checkbox-label`,
    checkboxInput: `${namespace}checkbox-input`,

};

export const DEFAULT_CONFIG: Options = {
    classNames: DEFAULT_CLASSNAMES,
    container: '.stack',
    fetchURL: null
};
