import {wrap} from '../Helpers/wrap';
import templates from '../Helpers/templates';
import DomainChecker from '../index';
import {ClassNames} from '../Interfaces/class-names';
import {DomainResponse} from '../Helpers/Fetch';
import {Locale} from '../Interfaces/locale';
import { logEvent } from '../Helpers/analytics';

export default class Cart {
    public readonly containerElement: HTMLElement;
    private readonly cartElement: HTMLElement;
    private emailCheckbox: HTMLDivElement;
    private websiteCheckbox: HTMLDivElement;
    private cartButton: HTMLElement;
    private cartRows: HTMLElement;
    private emptyCartMessage: HTMLParagraphElement;
    private priceSummary: HTMLParagraphElement;
    private cart: { [key: string]: DomainResponse } = {};
    private readonly classNames: ClassNames;
    private readonly locale: Locale;

    public constructor(container: HTMLElement, cart: HTMLElement, classNames: ClassNames, locale: Locale) {
        this.containerElement = container;
        this.cartElement = cart;
        this.locale = locale;
        this.classNames = classNames;
        this.build();
    }

    public isInCart(domain: string): boolean {
        return !!this.cart[domain];
    }

    public addDomain(domain: string, domainDetails: DomainResponse): void {

        if (!domain) {
            console.error('No domain provided when adding to cart');
            return;
        }
        if (this.isInCart(domain)) {
            console.error('Domain already in cart');
            return;
        }
        this.cart[domain] = domainDetails;
        const domainElement = this.getTemplate('cartRow', domain, domainDetails.formattedPrice);
        domainElement.querySelector(`.${this.classNames.cartRowRemove}`)?.addEventListener('click', () => {
            this.removeDomain(domain);
            this.resetGetItNowButton(domain);
        });
        const firstChild = this.cartRows.firstChild;
        if (firstChild) {
            this.cartRows.insertBefore(domainElement, firstChild);
        } else {
            this.cartRows.appendChild(domainElement);
        }
        this.updateCartDisplayState();
    }


    public removeDomain(domain: string): void {
        if (!domain) {
            console.error('No domain provided when removing from cart');
            return;
        }
        delete this.cart[domain];
        const domainElement = this.cartElement.querySelector(`[data-domain="${domain}"]`);
        if (domainElement) {
            domainElement.remove();
        }
        this.updateCartDisplayState();
    }

    private resetGetItNowButton(domain: string): void {
        const button = document.querySelector(`.${this.classNames.getItNowButton}[data-domain="${domain}"]`);
        if (button) {
            button.classList.remove(`${this.classNames.getItNowButtonSelected}`);
            button.textContent = 'Get it Now';
        }
    }

    private updateCartDisplayState(): void {
        if (Object.keys(this.cart).length > 0) {
            this.cartButton.style.display = 'block';
            this.websiteCheckbox.style.display = 'block';
            this.emailCheckbox.style.display = 'block';
            this.priceSummary.style.display = 'block';
            this.emptyCartMessage.style.display = 'none';
        } else {
            this.cartButton.style.display = 'none';
            this.priceSummary.style.display = 'none';
            this.emailCheckbox.style.display = 'none';
            this.websiteCheckbox.style.display = 'none';
            this.emptyCartMessage.style.display = 'block';
        }
    }

    private build(): void {
        this.cartButton = this.getTemplate('cartContinueButton');
        this.priceSummary = this.getTemplate('cartPriceSummary', this.locale);
        this.emptyCartMessage = this.getTemplate('cartCaption');
        this.emailCheckbox = this.getTemplate('checkbox', 'checkbox', 'I’ll also need Business Email');
        this.websiteCheckbox = this.getTemplate('checkbox', 'checkbox', 'Add a website - 30 day free trial');
        this.cartRows = this.getTemplate('cartRows');
        this.updateCartDisplayState();
        this.cartButton.addEventListener('click', () => {
            const domains: string[] = Object.keys(this.cart);
            logEvent('domains_cart_continue', { domains });
            const domainsString = domains.join('|');
            const url = window.location.hostname.includes('rocketspark.com')
                ? DomainChecker.PRODUCTION_GET_IT_NOW
                : DomainChecker.DEVELOPMENT_GET_IT_NOW;
            window.location.href = url + domainsString;
            let websiteChecked = (this.websiteCheckbox.getElementsByTagName('rs-checkbox').item(0).checked) ? 'Yes' : 'No';
            let emailChecked = (this.emailCheckbox.getElementsByTagName('rs-checkbox').item(0).checked) ? 'Yes' : 'No';
            if (websiteChecked === 'Yes') {
                logEvent('domains_website_checked', { websiteChecked });
            }
            if (emailChecked === 'Yes') {
                logEvent('domains_email_checked', { emailChecked });
            }
        });
        wrap(this.emptyCartMessage, this.cartElement);
        wrap(this.cartRows, this.cartElement);
        wrap(this.websiteCheckbox, this.cartElement);
        wrap(this.emailCheckbox, this.cartElement);
        wrap(this.cartButton, this.cartElement);
        wrap(this.priceSummary, this.cartElement);
        wrap(this.cartElement, this.containerElement);
    }
    private getTemplate(template: keyof typeof templates, ...args: any): any {
        return templates[template].call(this, {classNames: this.classNames}, ...args);
    }

}

