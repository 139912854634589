import {ClassNames} from '../Interfaces/class-names';
import {FetchDomain} from '../Helpers/Fetch';
import {Options} from '../Interfaces/options';
import {Locale} from '../Interfaces/locale';
import {localeTld} from '../Helpers/localeTld';
import templates from '../Helpers/templates';

export default class DomainSearch {
    public readonly element: HTMLElement;
    public readonly input: HTMLRsInputElement;
    private readonly button: HTMLRsButtonElement;
    private classNames: ClassNames;
    private readonly locale: Locale;
    private readonly searchAgain: HTMLRsIconElement;

    public constructor({
                           element,
                           config: {classNames,},
                           locale
                       }: {
        element: HTMLElement;
        config: Partial<Options>;
        locale: Locale
    }) {
        this.classNames = classNames;
        this.element = element;
        this.locale = locale;

        this.input = this.element.querySelector(`.${this.classNames.domainInput}`);
        this.searchAgain = Object.assign(document.createElement('rs-icon'), {
            className: this.classNames.searchAgain,
        });
        this.searchAgain.setAttribute('name', 'x');
        this.searchAgain.setAttribute('size', '12');
        this.button = this.element.querySelector(`.${this.classNames.searchButton}`);

        this.input.insertAdjacentElement('afterend', this.searchAgain);

        this.addEventListeners();
    }

    public showSearchAgain (): void {
        this.searchAgain.classList.add(this.classNames.searchAgainActive);
    }


    public setDomain(domain: string): this {
        this.input.value = domain;
        return this;
    }

    public setLoading(loading: boolean): void {
        if (!this.button.classList.contains(this.classNames.searchButtonCustom)) {
            return;
        }
        if (loading) {
            const loadingBar = this.getTemplate('loadingBar');
            this.button.innerHTML = '';
            this.button.appendChild(loadingBar);
        } else {
            this.button.innerHTML = 'Search';
        }
    }

    private addEventListeners(): void {
        this.input?.addEventListener('keyup', (event) => {
            this.input.removeAttribute('has-error');
            this.element.dispatchEvent(new CustomEvent('domainSearchInput'));
            if (event.key === 'Enter') {
                this.submit();
            }
        });

        this.button?.addEventListener('click', this.submit.bind(this));

        this.searchAgain.addEventListener('click', () => {
            this.element.dispatchEvent(new CustomEvent('domainSearchAgain'));
        });
    }
    private getTemplate(template: keyof typeof templates, ...args: any): any {
        return templates[template].call(this, {classNames: this.classNames}, ...args);
    }

    public submit(): void {
        if (this.input.value === '') {
            this.input.setAttribute('has-error', 'true');
            return;
        }

        this.input.value = this.input.value.toLocaleLowerCase();
        this.input.value = this.input.value.replace('www.', '');

        if (!this.input.value.includes('.')) {
            this.input.value += localeTld[this.locale];
        }

        this.input.value = this.input.value.replace(/ /g, '');

        this.element.dispatchEvent(new CustomEvent('domainSearchStart', {detail: this.input.value}));
        this.element.classList.add(this.classNames.inputContainerSearching);
        this.input.blur();
        this.setLoading(true);
        FetchDomain(this.input.value, this.locale).then(({
                                                             isAvailable,
                                                             formattedPrice,
                                                             success,
                                                             error,
                                                             domainSuggestions
                                                         }) => {
            this.setLoading(false);
            this.element.dispatchEvent(new CustomEvent('domainSearchFinish', {
                detail: {
                    success,
                    isAvailable,
                    formattedPrice,
                    error,
                    domainSuggestions
                }
            }));
        }).catch(() => {
            this.element.dispatchEvent(new CustomEvent('domainSearchFinish', {
                detail: {
                    success: false,
                    isAvailable: false,
                    formattedPrice: null
                }
            }));
        }).finally(() => {
            this.element.classList.remove(this.classNames.inputContainerSearching);
        });
    }
}
