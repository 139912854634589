import {Options} from '../Interfaces/options';
import {wrap} from './wrap';
import {ClassNames} from '../Interfaces/class-names';
import {Locale, Currency} from '../Interfaces/locale';

const templates = {
    container({classNames: {containerOuter}}: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: containerOuter,
        });
    },
    inputContainer({classNames: {inputContainer}}: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: inputContainer,
        });
    },
    domainSearch({classNames: {inputContainer, domainInput, searchButton}}: Options): HTMLDivElement {
        const container = Object.assign(document.createElement('div'), {
            className: inputContainer,
        });

        const input = Object.assign(document.createElement('input'), {
            className: domainInput,
            placeholder: 'Search for a domain',
        });

        const button = Object.assign(document.createElement('div'), {
            className: searchButton,
        });

        const button_container = Object.assign(document.createElement('div'), {
            className: 'custombutt_block',
        });

        const button_content = Object.assign(document.createElement('div'), {
            className: 'button-content custombutt_link',
            textContent: 'Search',
        });

        button_container.appendChild(button_content);
        button.appendChild(button_container);

        wrap(input, container);
        wrap(button, container);

        return container;
    },
    loadingBar({classNames: {loadingBarContainer, loadingBar}}: Options): HTMLDivElement {
        const container = Object.assign(document.createElement('div'), {
            className: loadingBarContainer,
        });

        const loading = Object.assign(document.createElement('span'), {
            className: loadingBar,
        });

        wrap(loading, container);
        return container;
    },
    popupSearch({
                    classNames: {
                        inputContainer,
                        domainInput,
                        searchButton,
                        searchButtonCustom,
                    },
                }: Options): HTMLDivElement {
        const container = Object.assign(document.createElement('div'), {
            className: inputContainer,
        });

        const input = Object.assign(document.createElement('input'), {
            className: domainInput,
            placeholder: 'Search for a domain',
        });

        const button = Object.assign(document.createElement('div'), {
            className: `${searchButton} ${searchButtonCustom}`,
            innerText: 'Search',
        });

        button.setAttribute('color', 'grey-light');

        wrap(input, container);
        wrap(button, container);

        return container;
    },
    popupContainer({classNames: {popupContainer}}: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: `${popupContainer} rs-component-shadow--popup`,
        });
    },
    domainContainer({classNames: {domainContainer}}: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: domainContainer,
        });
    },
    cartContainer({classNames: {cartContainer}}: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: cartContainer,
        });
    },
    cart({classNames: {cart, cartHeading}}: Options): HTMLElement {
        const shoppingCart = Object.assign(document.createElement('div'), {
            className: cart,
        });

        const heading = Object.assign(document.createElement('h3'), {
            textContent: 'Your cart',
            className: cartHeading,
        });

        shoppingCart.appendChild(heading);
        return shoppingCart;
    },
    cartContinueButton({classNames: {cartButton}}: Options): HTMLRsButtonElement {
        const button = Object.assign(document.createElement('rs-button'), {
            className: cartButton,
            textContent: 'Continue',
        });
        button.setAttribute('color', 'secondary');
        button.setAttribute('size', 'large');
        return button;
    },
    cartCaption({classNames: {cartCaption}}: Options): HTMLParagraphElement {
        return Object.assign(document.createElement('p'), {
            className: cartCaption,
            textContent: 'Your cart is currently empty',
        });
    },
    cartPriceSummary({classNames: {cartPriceSummary}}: Options, locale: Locale): HTMLParagraphElement {
        return Object.assign(document.createElement('div'), {
            className: cartPriceSummary,
            textContent: `Prices are in ${Currency.currency[locale]} and exclude ${Currency.taxLabel[locale]}`,
        });
    },
    cartRow({
                classNames: {
                    cartRow,
                    cartRowDomainDetailsURL,
                    cartRowDomainDetails,
                    cartRowDomainDetailsPrice,
                    cartRowRemove,
                    cartRowDomainDetailsContainer,
                }
            }: Options, domain: string, price: string): HTMLDivElement {
        const row = Object.assign(document.createElement('div'), {
            className: cartRow,
        });
        row.setAttribute('data-domain', domain);

        const container = Object.assign(document.createElement('div'), {
            className: cartRowDomainDetailsContainer,
        });
        const domainDetails = Object.assign(document.createElement('div'), {
            className: cartRowDomainDetails,
        });
        const domainUrl = Object.assign(document.createElement('div'), {
            className: cartRowDomainDetailsURL,
            textContent: domain,
        });
        domainDetails.appendChild(domainUrl);
        const domainPrice = Object.assign(document.createElement('div'), {
            className: cartRowDomainDetailsPrice,
            innerHTML: price,
        });
        container.appendChild(domainDetails);
        container.appendChild(domainPrice);

        const removeDomain = Object.assign(document.createElement('rs-icon'), {
            className: cartRowRemove,
            name: 'x',
        });
        removeDomain.setAttribute('size', '12');

        row.appendChild(container);
        row.appendChild(removeDomain);
        return row;
    },
    checkbox({classNames: {checkboxContainer, checkboxLabel, checkboxInput}}: Options, id: string, label: string): HTMLDivElement {
        const container = Object.assign(document.createElement('div'), {
            className: checkboxContainer,
        });
 
        const input = Object.assign(document.createElement('rs-checkbox'), {
            id: id,
            className: checkboxInput,
        });

        const labelElement = Object.assign(document.createElement('label'), {
            htmlFor: id,
            className: checkboxLabel,
            textContent: label,
        });
        
        labelElement.addEventListener('click', () => {
            input.checked = !input.checked;
        });
    
        container.appendChild(input);
        input.appendChild(labelElement);

        return container;
    },
    cartRows({classNames: {cartRowsContainer}}: Options): HTMLDivElement {
        console.log(cartRowsContainer);
        return Object.assign(document.createElement('div'), {
            className: cartRowsContainer,
        });
    },
    searchHeading({classNames: {searchHeading}}: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: searchHeading,
        });
    },
    icon(classNames: Partial<ClassNames>, className: string): HTMLRsIconElement {
        const icon = Object.assign(document.createElement('rs-icon'), {
            className,
        });
        icon.setAttribute('size', '32');
        return icon;
    },
    searchResultText({classNames: {searchResultCaption}}: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: searchResultCaption,
        });
    },
    searchResultsContainer({
                               classNames: {
                                   searchAlternativeContainer,
                                   searchAlternativeHeader,
                                   searchPreferredHeader,
                                   searchAlternativeContainerTitle,
                                   searchAlternativeContainerContent,
                                   domainSearchContainer,
                               },
                           }: Options, locale: Locale, title = 'Other great alternatives', rowType: 'preferred' | 'alternative'): HTMLDivElement {
        const headerContainer = Object.assign(document.createElement('div'), {
            className: rowType === 'preferred' ? searchPreferredHeader : searchAlternativeHeader,
        });
        const header = Object.assign(document.createElement('div'), {
            className: searchAlternativeContainerTitle,
            textContent: title,
        });
        const content = Object.assign(document.createElement('div'), {
            className: searchAlternativeContainerContent,
            textContent: `Prices are in ${Currency.currency[locale]} and exclude ${Currency.taxLabel[locale]}`,
        });
        const container = Object.assign(document.createElement('div'), {
            className: rowType === 'preferred' ? searchAlternativeContainer : `${searchAlternativeContainer} ${domainSearchContainer}`,
        });
        wrap(header, headerContainer);
        wrap(content, headerContainer);
        wrap(headerContainer, container);

        return container;
    },
    searchAlternativeRow({
                             classNames: {
                                 searchAlternativeRow,
                                 searchPreferredRow,
                                 searchPreferredPill,
                                 searchAlternativeRowHeader,
                                 searchAlternativeRowHeaderContainer,
                                 searchAlternativeRowFetching,
                                 searchAlternativePricingContainer,
                                 getItNowButtonPreferred,
                                 getItNowButtonAlternative,
                                 getItNowButtonContainer,
                                 getItNowButton,
                                 loadingBarContainer,
                                 loadingBar
                             },
                         }: Options, rowType: 'preferred' | 'alternative', domain: string): HTMLDivElement {
        const headerContainer = Object.assign(document.createElement('div'), {
            className: searchAlternativeRowHeaderContainer,
        });
        const header = Object.assign(document.createElement('div'), {
            className: searchAlternativeRowHeader,
        });
        if (rowType === 'preferred') {
            const pill = Object.assign(document.createElement('span'), {
                className: searchPreferredPill,
                textContent: 'It\'s Available',
            });
            wrap(pill, headerContainer);
        }
        wrap(header, headerContainer);
        const price = Object.assign(document.createElement('div'), {
            className: searchAlternativePricingContainer,
        });

        const getItNowContainer = Object.assign(document.createElement('div'), {
            className: getItNowButtonContainer,
        });

        const button = Object.assign(document.createElement('div'), {
            className: getItNowButton,
        });
        button.setAttribute('data-domain', domain);
        if (rowType === 'preferred') {
            button.classList.add(getItNowButtonPreferred);
        } else {
            button.classList.add(getItNowButtonAlternative);
        }

        const loadingContainer = Object.assign(document.createElement('div'), {
            className: loadingBarContainer,
        });

        const loading = Object.assign(document.createElement('span'), {
            className: loadingBar,
        });

        wrap(loading, loadingContainer);
        wrap(loadingContainer, button);
        wrap(button, getItNowContainer);

        const row = Object.assign(document.createElement('div'), {
            className: `${searchAlternativeRow} ${rowType === 'preferred' ? `${searchPreferredRow}` : ''} ${searchAlternativeRowFetching}`,
        });
        wrap(headerContainer, row);
        wrap(price, row);
        wrap(getItNowContainer, row);

        return row;
    },
};

export default templates;