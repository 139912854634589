
declare global {
    interface Window {
        dataLayer: any;
    }
}

let logging = false;

export function logEvent(
    name: string, 
    params: { [key: string]: string | string[] }
): void {
    if (!window.dataLayer && logging === false) {
        console.warn('dataLayer is not defined, gtag.js not included. Logging to console instead.');
        logging = true;
    }

    if (logging) {
        console.log(name, params);
        return;
    }

    window.dataLayer.push({event: name, ...params});
}