import {ClassNames} from '../Interfaces/class-names';

export default class SearchResultCaption {
    public set price(value: string | null) {
        this._price = value;
        if (value === null) {
            this.removeMessage();
        } else if (value === 'unavailable') {
            this.setMessage('This domain is already taken. Please try an alternative option');
        } else {
            this.setMessage(`${this.base} <strong class="${this.classNames.searchResultCaptionPrice}">${this._price}</strong>`);
        }
    }

    public readonly element: HTMLRsHeaderElement;
    private _price: string;
    private readonly base = 'You can get this domain now for';
    private classNames: ClassNames;

    public constructor({
                           element,
                           classNames
                       }: {
        element: HTMLRsHeaderElement;
        classNames: ClassNames
    }) {
        this.element = element;
        this.classNames = classNames;
    }

    public setMessage(message: string): this {
        this.element.innerHTML = message;
        this.element.classList.add(this.classNames.searchResultCaptionActive);
        return this;
    }

    public removeMessage(): this {
        this.element.innerHTML = '';
        this.element.classList.remove(this.classNames.searchResultCaptionActive);
        return this;
    }
}
