import {ClassNames} from '../Interfaces/class-names';
import {Options} from '../Interfaces/options';
import {wrap} from '../Helpers/wrap';
import SearchHeading from './SearchHeading';
import templates from '../Helpers/templates';
import DomainSearch from './DomainSearch';
import SearchResultCaption from './SearchResultCaption';
import DomainChecker from '../index';
import {DomainResponse, DomainSuggestions} from '../Helpers/Fetch';
import SearchAlternatives from './SearchAlternatives';
import {Locale} from '../Interfaces/locale';
import Cart from './Cart';


export default class PopupContainer {
    public readonly element: HTMLElement;
    public domainSearch: DomainSearch;
    private readonly domainsElement: HTMLElement;
    private readonly cart: Cart;
    private readonly classNames: ClassNames;
    private readonly locale: Locale;
    private readonly fetchURL: string;
    private heading: SearchHeading;

    private searchResultText: SearchResultCaption;
    private closeIcon: HTMLRsIconElement;
    private domainAlternatives: SearchAlternatives;
    private domainPreferred: SearchAlternatives;


    public constructor({
                           element,
                           config: {classNames, fetchURL},
                           locale
                       }: {
        element: HTMLElement;
        config: Options;
        locale: Locale
    }) {
        this.fetchURL = fetchURL;
        this.locale = locale;
        this.classNames = classNames;
        this.element = element;
        this.domainsElement = this.getTemplate('domainContainer');
        this.cart = new Cart(this.getTemplate('cartContainer'), this.getTemplate('cart'), this.classNames, this.locale,);
        wrap(this.domainsElement, this.element);
        wrap(this.cart.containerElement, this.element);

        this.build();
    }

    public setLoading(loading: boolean): void {
        this.domainSearch.setLoading(loading);
        this.domainAlternatives.element.style.display = loading ? 'none' : 'block';
    }

    public open(): this {
        wrap(this.element, document.body);
        setTimeout(() => {
            this.element.classList.add(this.classNames.popupContainerActive);
        });
        document.querySelector('#shadow')?.classList.add('dc__checker-open');
        return this;
    }

    public close(): this {
        document.querySelector('#shadow')?.classList.remove('dc__checker-open');
        this.element.classList.remove(this.classNames.popupContainerActive);
        setTimeout(() => {
            this.element.remove();
            this.resetLoadingUi();
        }, 150);
        return this;
    }


    public setHeading(heading: string): this {
        this.heading.heading = heading;
        return this;
    }

    public setPrice(price?: string): this {
        this.searchResultText.price = price;
        return this;
    }

    public setCaptionMessage(message: string): this {
        this.searchResultText.setMessage(message);
        return this;
    }

    public setDomain(domain: string): this {
        this.domainSearch.setDomain(domain);
        return this;
    }

    public showSearchAgain(): void {
        this.domainSearch.showSearchAgain();
    }

    public showAndFetchDomainRows(domainDetail: DomainResponse): this {
        const suggestions = domainDetail.domainSuggestions;
        if (domainDetail.isAvailable) {
            this.domainPreferred.element.style.display = 'block';
            const domain = this.domainSearch.input.value;
            const preferredSuggestion: DomainSuggestions = {
                prefix: domain.split('.')[0],
                suffixes: [domain.substring(domain.indexOf('.'))]
            };
            // This shows the preferred domain
            this.domainPreferred.showDomains(preferredSuggestion);
        } else {
            this.hidePreferredDomain();
        }
        // This shows the alternative domains
        this.domainAlternatives.showDomains(suggestions);
        return this;
    }

    private hideSearchAlternativeRows(): void {
        const alternativeRows = this.element.querySelectorAll(`.${this.classNames.searchAlternativeContainer}`);
        alternativeRows.forEach(row => {
            (row as HTMLElement).style.display = 'none';
        });
    }

    private hidePreferredDomain(): void {
        this.domainPreferred.element.style.display = 'none';
    }

    private resetLoadingUi(message = 'Checking your domain...'): void {
        this.setHeading(message);
        this.setPrice(null);
    }

    private getTemplate(template: keyof typeof templates, ...args: any): any {
        return templates[template].call(this, {classNames: this.classNames}, ...args);
    }

    private build(): void {

        this.closeIcon = this.getTemplate('icon', this.classNames.popupClose);
        this.closeIcon.setAttribute('name', 'x');
        this.closeIcon.setAttribute('size', '24');
        this.heading = new SearchHeading({
            element: this.getTemplate('searchHeading')
        });


        const searchContainer = Object.assign(document.createElement('div'), {
            className: this.classNames.popupSearchContainer
        });

        this.searchResultText = new SearchResultCaption({
            element: this.getTemplate('searchResultText'),
            classNames: this.classNames
        });

        this.domainSearch = new DomainSearch({
            element: this.getTemplate('popupSearch'),
            config: {
                classNames: this.classNames,
                fetchURL: this.fetchURL
            },
            locale: this.locale
        });

        this.domainPreferred = new SearchAlternatives({
            element: this.getTemplate('searchResultsContainer', this.locale, 'Your preferred Domain', 'preferred'),
            classNames: this.classNames,
            locale: this.locale,
            rowType: 'preferred',
            cart: this.cart,
        });

        this.domainAlternatives = new SearchAlternatives({
            element: this.getTemplate('searchResultsContainer', this.locale),
            classNames: this.classNames,
            locale: this.locale,
            rowType: 'alternative',
            cart: this.cart,
        });

        wrap(this.closeIcon, this.domainsElement);
        wrap(this.heading.element, this.domainsElement);
        wrap(this.searchResultText.element, this.domainsElement);
        wrap(this.domainSearch.element, searchContainer);
        wrap(searchContainer, this.domainsElement);
        wrap(this.domainPreferred.element, this.domainsElement);
        wrap(this.domainAlternatives.element, this.domainsElement);


        this.domainSearch.element.addEventListener('domainSearchStart', event => {
            this.resetLoadingUi();
            this.hideSearchAlternativeRows();
            event.stopImmediatePropagation();
        });

        this.domainSearch.element.addEventListener('domainSearchFinish', (event: CustomEvent<DomainResponse>) => {
            event.stopImmediatePropagation();
            DomainChecker.setPopupStatus(this, event.detail);
            this.showAndFetchDomainRows(event.detail);
        });

        this.closeIcon.addEventListener('click', () => {
            this.close();
        });

        this.domainSearch.input.addEventListener('input', () => {
            this.resetLoadingUi('Enter a domain to register');
        });

        this.domainSearch.element.addEventListener('domainSearchAgain', () => {
            this.resetLoadingUi('Enter a domain to register');
            this.domainSearch.input.value = '';
            this.domainSearch.input?.focus();
        });
    }
}
